import React from "react";
import "./Button.scss";

export default function Button({ onClick, children }) {
  return (
    <div className="Button" onClick={onClick}>
      {children}
    </div>
  );
}
