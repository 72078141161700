import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Connector } from "mqtt-react-hooks";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Connector
        options={{
          username: "mqttx_90887554",
          password: "SfBh64rcr8hRHd7bmhzc",
        }}
        brokerUrl="wss://api.di10.datalyze-solutions.com/ws/mqtt"
      >
        <App />
      </Connector>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
