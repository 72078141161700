import axios from "axios";
import { useCallback, useEffect, useState } from "react";
export let baseUrl = "https://api.di10.datalyze-solutions.com/";
export default function useApi({ method = "get", path = "" }) {
  let [res, setRes] = useState();

  let exec = useCallback(
    (payload) => {
      if (method === "get") {
        axios.get(baseUrl + path).then((r) => setRes(r.data));
      }

      if (method === "post") {
        axios.get(baseUrl + path, payload).then((r) => setRes(r.data));
      }
    },
    [method, path]
  );
  useEffect(() => {
    if (method === "get") {
      exec();
    }
  }, [method, exec]);
  return { exec, res };
}
