import React from "react";
import "./Layout.scss";
import { FaUserAlt } from "react-icons/fa";
import Spacer from "../Spacer/Spacer";
export default function Layout({ className, children }) {
  return (
    <div className={"Layout " + className}>
      <div className="header">
        <div className="logo">
          <img alt="logo" src="/logo.png"></img>
        </div>
        <div className="platformName">Platform Name</div>
        <div className="flex1"></div>
        <div className="userArea">
          <FaUserAlt />
          <Spacer w={12}></Spacer>
          user@siemens.de
        </div>
      </div>
      <div className="main">
        <div className="inner">{children}</div>
      </div>
    </div>
  );
}
