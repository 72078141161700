import { Route, Routes } from "react-router-dom";
import Abnahme from "./screens/Abnahme/Abnahme";
import AbnahmeSchritte from "./screens/AbnahmeSchritte/AbnahmeSchritte";
import Home from "./screens/Home/Home";
import Login from "./screens/Login/Login";

function App() {


  return (
    <Routes>
      <Route path="/" element={<Login></Login>}></Route>
      <Route path="/home" element={<Home></Home>}></Route>
      <Route
        path="/abnahmeSchritte"
        element={<AbnahmeSchritte></AbnahmeSchritte>}
      ></Route>
      <Route path="/abnahme/:id" element={<Abnahme></Abnahme>}></Route>
    </Routes>
  );
}

export default App;
