import React from "react";
import Layout from "../../atoms/Layout/Layout";
import ProjectTable from "../../atoms/ProjectTable/ProjectTable";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Home.scss";

export default function Home() {
  return (
    <Layout className="Home">
      <Spacer h={24}></Spacer>
      <div className="flex">
        <div className="title">Projekte</div>
        <div className="flex1"></div>
        <select name="" id="">
          <option>Sortieren nach Name</option>
        </select>
        <Spacer w={12}></Spacer>
        <select name="" id="">
          <option>Alle Projekte</option>
        </select>
      </div>
      <Spacer h={24}></Spacer>
      <ProjectTable></ProjectTable>
    </Layout>
  );
}
