import React from "react";
import { Link } from "react-router-dom";
import Spacer from "../Spacer/Spacer";
import "./ProjectTable.scss";
export default function ProjectTable() {
  return (
    <div className="ProjectTable">
      <TableRow></TableRow>
      <TableRow></TableRow>
      <TableRow></TableRow>
      <TableRow></TableRow>
      <TableRow></TableRow>
    </div>
  );
}

function TableRow() {
  return (
    <Link to="/abnahmeSchritte" className="TableRow">
      <div className="cell">
        <div className="small">Name</div>
        <div className="big">Titel des Projektes</div>
      </div>
      <div className="cell noFlex">
        <img
          className="avatar"
          alt="avatar"
          src={"https://i.pravatar.cc/50?" + Math.random()}
        ></img>
      </div>
      <Spacer w={12}></Spacer>
      <div className="cell">
        <div className="small">Projektleiter</div>
        <div className="big">Peter Eins</div>
      </div>
      <div className="cell">
        <div className="small">Prüfungstermin</div>
        <div className="">22.10.1991 14:00</div>
      </div>
      <div className="cell noFlex">
        <Status num={Math.floor(Math.random() * 4 + 1)}></Status>
      </div>
    </Link>
  );
}

export function Status({ num }) {
  const stati = ["", "Planung", "Einladung", "Prüfung", "Abgeschlossen"];
  return (
    <div className="cell">
      <div className="status">
        <div className={"bar " + (num >= 1 ? "active" : " ")}></div>
        <div className={"bar " + (num >= 2 ? "active" : " ")}></div>
        <div className={"bar " + (num >= 3 ? "active" : " ")}></div>
        <div className={"bar " + (num >= 4 ? "active" : " ")}></div>
      </div>
      <Spacer h={4}></Spacer>
      {stati[num]}
    </div>
  );
}
