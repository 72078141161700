import { useSubscription } from "mqtt-react-hooks";
import React, { useEffect, useState } from "react";
import { FaArrowCircleLeft, FaFileAlt, FaFilePdf } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ArView from "../../atoms/ArView/ArView";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import useApi, { baseUrl } from "../../hooks/useApi";
import useSensors from "../../hooks/useSensors";
import "./Abnahme.scss";

export default function Abnahme() {
  let [tab, setTab] = useState(1);
  let { id: survey } = useParams();

  let { addBatch } = useSensors();
  const { message } = useSubscription([
    "none/mqttx_90887554/telemetry/sensor_values",
  ]);
  useEffect(() => {
    //got a message from mqtt
    if (message) {
      let data = JSON.parse(message.message);
      addBatch(data);
      console.log(data);
    }
  }, [message, addBatch]);

  return (
    <Layout className="Abnahme">
      <div className="abnahmeHeader">
        <div className="back" onClick={() => window.history.back()}>
          <FaArrowCircleLeft></FaArrowCircleLeft>
        </div>
        <Spacer w={12}></Spacer>
        <div className="name">Projekt Name</div>
        <div className="flex1"></div>
        <div className="tabs">
          <Link
            to={"/abnahmeSchritte"}
            className={"tab " + (tab === 0 ? "active " : " ")}
          >
            Projektdetails
          </Link>
          <div
            onClick={() => setTab(1)}
            className={"tab " + (tab === 1 ? "active " : " ")}
          >
            AR Ansicht
          </div>
          <div
            onClick={() => setTab(2)}
            className={"tab " + (tab === 2 ? "active " : " ")}
          >
            Dokumente
          </div>
        </div>
      </div>
      {tab === 1 && <ArView surveyId={survey}></ArView>}
      {tab === 2 && <Documents></Documents>}
    </Layout>
  );
}

export function Person({ name, role, avatar }) {
  return (
    <div className="person">
      <div className="avatarWarp">
        <img
          alt="avatar"
          className="avatar"
          src={"https://i.pravatar.cc/50?" + Math.random()}
        ></img>
      </div>
      <div className="info">
        <div className="role">{role}</div>
        <div className="name">{name}</div>
      </div>
    </div>
  );
}
function FileIcon({ type }) {
  if (type === "pdf") {
    return <FaFilePdf></FaFilePdf>;
  }
  return <FaFileAlt></FaFileAlt>;
}
function Documents() {
  let { res: files } = useApi({ path: "files" });
  console.log(files);
  return (
    <div className="documents">
      {files &&
        files.map((f) => {
          return (
            <a
              href={baseUrl + "files/" + f.id + "?download=true"}
              target={"_blank"}
              rel="noreferrer"
              className="row"
            >
              <div className="icon">
                <FileIcon type={f.fletype} />
              </div>
              <div className="fileName">{f.name}</div>
              <div className="changeDate">
                <div className="top">Herunterladen</div>
                <div className="val">{f.path}</div>
              </div>
            </a>
          );
        })}

      <Spacer h={12}></Spacer>
      <Button>Dokument hochladen</Button>
    </div>
  );
}
