import create from "zustand";

const useSensors = create((set, get) => ({
  batches: [],
  addBatch: (batch) => {
      console.log("set batches")
    set({ batches: [...get().batches, batch] });
  },
}));

export default useSensors;
