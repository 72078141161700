import React from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Layout from "../../atoms/Layout/Layout";
import { Status } from "../../atoms/ProjectTable/ProjectTable";
import Spacer from "../../atoms/Spacer/Spacer";
import useApi from "../../hooks/useApi";
import { Person } from "../Abnahme/Abnahme";
import "./AbnahmeSchritte.scss";

export default function AbnahmeSchritte() {
  return (
    <Layout className="AbnahmeSchritte">
      <div className="abnahmeHeader">
        <div className="back" onClick={() => window.history.back()}>
          <FaArrowCircleLeft></FaArrowCircleLeft>
        </div>
        <Spacer w={12}></Spacer>
        <div className="name">Projekt Name</div>
        <div className="flex1"></div>
        <div className="tabs">
          <div onClick={() => {}} className={"tab active"}>
            Projektdetails
          </div>
          <div onClick={() => {}} className={"tab"}>
            AR Ansicht
          </div>
          <div onClick={() => {}} className={"tab"}>
            Dokumente
          </div>
        </div>
      </div>
      <ProjectDetails></ProjectDetails>
    </Layout>
  );
}

function ProjectDetails() {
  let { res: surveys } = useApi({ path: "surveys" });

  console.log(surveys);
  return (
    <div className="wrap">
      <div className="left">
        <div className="projectDetails">
          <div className="title">Prüfablauf</div>
          <Spacer h={12}></Spacer>
          {surveys &&
            surveys.map((s, i) => {
              return (
                <Link to={"/abnahme/" + s.id} className="row">
                  <div className="col left">
                    <div className="top">Abnahmeschritt {i + 1}</div>
                    <div className="val">{s.header}</div>
                  </div>
                  <div className="col right">
                    <div className="top">Mo. 22.10.2022</div>
                    <div className="val">14:00 - 16:20</div>
                  </div>
                </Link>
              );
            })}
          <Spacer h={24}></Spacer>
          <Button>Daten Importieren</Button>
        </div>
      </div>
      <div className="mid"></div>
      <div className="right">
        <div className="title small">Status</div>
        <Spacer h={8}></Spacer>
        <Status num={2}></Status>
        <Spacer h={24}></Spacer>
        <div className="title small">Beteiligte Personen</div>
        <Spacer h={8}></Spacer>
        <Person name={"Hans"} role={"Monteur"}></Person>
        <Person name={"Hans"} role={"Monteur"}></Person>
        <Person name={"Hans"} role={"Monteur"}></Person>
        <Person name={"Hans"} role={"Monteur"}></Person>
      </div>
    </div>
  );
}
