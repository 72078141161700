import React, { useState } from "react";
import { FaChartBar, FaEye, FaEyeSlash } from "react-icons/fa";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import useApi from "../../hooks/useApi";
import Spacer from "../Spacer/Spacer";
import "./ArView.scss";

import useSensors from "../../hooks/useSensors";

export default function ArView({ surveyId }) {
  let { res: survey } = useApi({ path: "surveys/" + surveyId });
  let { res: sensors } = useApi({ path: "surveys/" + surveyId + "/sensors" });
  let [motor, setMotor] = useState(1);
  let [hiddenSensors, setHiddenSensors] = useState([]);
  console.log(sensors);
  return (
    <div className="ArView">
      <div className="mainArView">
        <div className="arViewHeader">
          <div className="left">
            <div className="top">Abnahmeschritt 1</div>
            <div className="val">{survey && survey.header}</div>
          </div>
          <div className="right">
            <div className="top">Montag, 24.04.2022</div>
            <div className="val">14:00-15:00 Uhr</div>
          </div>
        </div>
        <div className="arScreen">
          <iframe
            title="AR View"
            allowFullscreen="true"
            src="https://stream.di10.datalyze-solutions.com/receiver/index.html"
          ></iframe>
        </div>
      </div>
      <div className="sidebar">
        <div className="tabs small">
          <div
            onClick={() => setMotor(1)}
            className={"tab " + (motor === 1 ? "active " : " ")}
          >
            Motor 1
          </div>
          <div
            onClick={() => setMotor(2)}
            className={"tab " + (motor === 2 ? "active " : " ")}
          >
            Motor 2
          </div>
        </div>

        <Spacer h={24}></Spacer>
        <div className="title small">Verlaufsdiagramm</div>
        <Spacer h={24}></Spacer>
        <Chart
          hiddenSensors={hiddenSensors}
          setHiddenSensors={setHiddenSensors}
          sensors={sensors}
          motor={motor}
          survey={surveyId}
        ></Chart>
        <Spacer h={48}></Spacer>
        <div className="title small">Sensoren</div>
        <Sensors
          hiddenSensors={hiddenSensors}
          setHiddenSensors={setHiddenSensors}
          sensors={sensors}
          motor={motor}
          survey={surveyId}
        ></Sensors>
      </div>
    </div>
  );
}

const colors = [
  "#1ABC9C",
  "#16A085",
  "#2ECC71",
  "#27AE60",
  "#3498DB",
  "#2980B9",
  "#9B59B6",
  "#8E44AD",
  "#34495E",
  "#2C3E50",
  "#F1C40F",
  "#F39C12",
  "#E67E22",
  "#D35400",
  "#E74C3C",
  "#C0392B",
];

function Chart({ motor, survey, sensors, hiddenSensors }) {
  let { batches } = useSensors();
  let currentBatch = batches
    .filter((b) => b.survey_id === parseInt(survey) && b.motor_id === motor)
    .map((b, i) => {
      let obj = {};
      b.values.forEach((v) => {
        obj[v.sensor_id] = v.value;
      });
      return obj;
    });
  console.log({ currentBatch, sensors });
  return (
    <div className="chart">
      {currentBatch && sensors && (
        <ResponsiveContainer width={"100%"} aspect={2}>
          <LineChart
            margin={{ top: 10, left: -20, right: 0 }}
            data={currentBatch}
          >
            {sensors
              .filter((s) => !hiddenSensors.includes(s.id))
              .map((s, i) => {
                return (
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    dataKey={s.id}
                    stroke={colors[s.id % colors.length]}
                    strokeWidth={2}
                  />
                );
              })}

            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              padding={{ left: 12 }}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              interval={"preserveStart"}
            ></YAxis>
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

function Sensors({ sensors, motor, survey, hiddenSensors, setHiddenSensors }) {
  let { batches } = useSensors();
  //filter batch by motor, survey
  console.log(batches, motor, survey);
  let currentBatch = batches.filter(
    (b) => b.survey_id === parseInt(survey) && b.motor_id === motor
  );
  currentBatch = currentBatch[currentBatch.length - 1];
  // console.log("Current batch");
  // console.log({ currentBatch });

  return (
    <div className="sensors">
      <div className="sensorCategory">Temperaturen</div>
      {sensors &&
        sensors.map((s, i) => {
          return (
            <Sensor
              name={s.name}
              color={colors[s.id % colors.length]}
              hide={() => setHiddenSensors([...hiddenSensors, s.id])}
              show={() =>
                setHiddenSensors(hiddenSensors.filter((v) => v !== s.id))
              }
              disabled={hiddenSensors.includes(s.id)}
              value={
                currentBatch &&
                currentBatch.values.find((b) => b.sensor_id === s.id)?.value
              }
            ></Sensor>
          );
        })}
    </div>
  );
}

function Sensor({ name, color, value, disabled, hide, show }) {
  return (
    <div className={"sensor " + (disabled ? "disabled " : "")}>
      <div
        className="sensorBar"
        style={{ backgroundColor: disabled ? undefined : color }}
      ></div>
      <div className="sensorButton">
        <FaChartBar></FaChartBar>
      </div>

      <div className="sensorButton" onClick={disabled ? show : hide}>
        {disabled ? <FaEyeSlash></FaEyeSlash> : <FaEye />}
      </div>
      <div className="sensorName">{name}</div>
      <div className="sensorValue">{value}</div>
    </div>
  );
}
