import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Login.scss";

export default function Login() {
  let nav = useNavigate();
  return (
    <div className="Login">
      <div className="title">Siemens ID Login Service</div>
      <Spacer h={24}></Spacer>
      <div className="loginLinks">
        <div className="loginLink">Anmelden</div>
        <div className="flex1"></div>
        <div className="loginLink">Registrieren</div>
      </div>
      <Spacer h={12}></Spacer>
      <div className="loginBox">
        <input type="text" placeholder="ihre@email.de" className="loginInput" />
        <Spacer h={12}></Spacer>
        <input
          type="password"
          placeholder="Ihr Passwort"
          className="loginInput"
        />
        <Spacer h={12}></Spacer>
        <div className="flex">
          <div className="flex1"></div>
          <div className="forgotLink">Passwort vergessen</div>
        </div>
        <Spacer h={12}></Spacer>
        <Button onClick={() => nav("/home")}>Anmelden</Button>
      </div>
      <Spacer h={12}></Spacer>
      <div className="loginBox small">
        <div className="myIdIcon">!D</div>
        <Spacer w={20}></Spacer>
        Anmelden mit MyID (Employees)
      </div>
    </div>
  );
}
